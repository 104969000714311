.react-select__control {
  border: none !important;
  border-width: 0;
  background-color: transparent !important;
  width: 100%;
  box-shadow: none !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__value-container {
  padding-right: 0 !important;
}

.react-select__indicators svg {
  color: black !important;
}